import * as React from 'react'
import {getPreliminaryInvoice, getTaxConfig} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {SummaryContainer} from '../../summary-container'
import {InnerSummary} from '../../inner-summary'
import {ButtonStyle} from '../../button-style'
import {FooterProps} from '.'

export const Footer = ({
  selectedTicketsWithPlacesCount,
  tickets,
  event,
  paidPlan,
  selectedTickets,
  showAccessibilityMode,
  setShowAccessibilityMode,
  onButtonClick,
}: FooterProps) => {
  const {t} = useTranslation()
  const handleButtonClick = () => (onButtonClick ? onButtonClick() : setShowAccessibilityMode(false))

  return (
    <SummaryContainer inAccessibilityMode={showAccessibilityMode}>
      <InnerSummary
        invoice={getPreliminaryInvoice(tickets, selectedTickets, getTaxConfig(event), paidPlan)}
        selectedTickets={selectedTicketsWithPlacesCount}
        withoutBreakdown
      />
      <ButtonStyle onClick={handleButtonClick} text={t('seatings_viewBasket')} dataHook={DH.VIEW_BASKET} />
    </SummaryContainer>
  )
}
