import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Container} from '../common/container'
import {Title} from '../common/title'
import s from './order-expired.scss'
import {OrderExpiredProps} from '.'

export const OrderExpired: React.FC<OrderExpiredProps> = ({t, homePageUrl}) => (
  <Container dataHook={DH.ORDER_EXPIRED}>
    <div style={{textAlign: 'center'}}>
      <Title text={t('pageExpired')} />
      <a className={s.link} data-hook={DH.BACK_TO_SITE} href={homePageUrl}>
        {t('backToSite')}
      </a>
    </div>
  </Container>
)
