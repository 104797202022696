import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {Divider} from 'wix-ui-tpa/Divider'
import {classes} from './filter-container.st.css'

export const FilterContainer: React.FC = ({children}) => {
  const {isMobile} = useEnvironment()

  return (
    <div className={isMobile ? classes.container : classes.desktopContainer}>
      {children}
      {isMobile ? <Divider className={classes.divider} /> : null}
    </div>
  )
}
