import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {isHollowButton} from '../../../selectors/settings'
import cl from '../../classnames.scss'
import {Subtitle} from '../../subtitle'
import {getElementStyle} from '../../../../../commons/utils/dom'
import s from './about-section.scss'
import {AboutSectionProps} from './index'

export interface AboutSectionState {
  collapsed: boolean
  overflows: boolean
}

const WRAPPER_ID = 'events-details-page-about-wrapper'
const INNER_ID = 'events-details-page-about-inner'

export class AboutSection extends React.Component<AboutSectionProps, AboutSectionState> {
  contentRef
  focusableList = ['a', 'button', 'input', 'textarea', 'select']

  constructor(props: AboutSectionProps) {
    super(props)
    this.state = {
      collapsed: this.props.readMoreButtonVisible,
      overflows: false,
    }
  }

  render() {
    const {aboutTitleText, readMoreText, readLessText, buttonStyle, compact, isMobile, eventId} = this.props
    const {collapsed} = this.state
    const wrapperStyle = this.state.collapsed ? {maxHeight: '12em'} : {}

    return (
      <div
        className={classNames(s.container, {[s.mobileContainer]: isMobile}, cl.evTextFont, cl.evTextColor)}
        data-hook={DH.ABOUT_SECTION}
      >
        <Subtitle dataHook="about" text={aboutTitleText} compact={compact} />
        <div
          data-hook={DH.ABOUT_SECTION_WRAPPER}
          id={WRAPPER_ID}
          className={classNames(s.wrapper, {[s.marginBottom]: !this.state.overflows})}
          style={wrapperStyle}
        >
          <div
            id={INNER_ID}
            data-hook={DH.ABOUT_SECTION_TEXT}
            ref={ref => (this.contentRef = ref)}
            className={isHollowButton(buttonStyle) ? cl.aboutHollowText : cl.aboutFullText}
            role="text"
            key={eventId}
            dangerouslySetInnerHTML={{__html: this.getAboutText()}}
          ></div>
        </div>
        {this.state.overflows ? (
          <button
            data-hook={DH.ABOUT_SECTION_BUTTON}
            aria-controls={INNER_ID}
            aria-expanded={!collapsed}
            className={this.getButtonClasses()}
            onClick={this.toggle}
          >
            {collapsed ? readMoreText : readLessText}
          </button>
        ) : null}
      </div>
    )
  }

  componentDidUpdate(props: AboutSectionProps, state: AboutSectionState) {
    if (this.props.aboutText !== props.aboutText) {
      this.setOverflow()
    }
    if (this.props.readMoreButtonVisible !== props.readMoreButtonVisible) {
      const overflows = this.props.readMoreButtonVisible ? false : state.overflows
      this.setState({collapsed: this.props.readMoreButtonVisible, overflows})
    }
    if (this.state.collapsed !== state.collapsed) {
      if (this.props.isEditor) {
        this.setOverflow()
      } else {
        this.props.updateLayout()
      }
    }
  }

  componentDidMount() {
    const {collapsed} = this.state
    this.appendAboutText(collapsed)
    this.setOverflow()
  }

  toggle = () => {
    const {collapsed} = this.state
    this.appendAboutText(!collapsed)
    this.setState({collapsed: !this.state.collapsed})
  }

  getButtonClasses = () => {
    const {buttonStyle, readMoreButtonExtraMargin} = this.props

    return classNames(s.readMore, {
      [cl.readMoreHollowTextColor]: isHollowButton(buttonStyle),
      [cl.readMoreTextColor]: !isHollowButton(buttonStyle),
      [s.readMoreExtraMargin]: readMoreButtonExtraMargin,
    })
  }

  setOverflow() {
    const wrapper = document.getElementById(WRAPPER_ID)
    const inner = document.getElementById(INNER_ID)
    const wrapperHeight = parseInt(getElementStyle(wrapper, 'height'), 10)
    const innerHeight = parseInt(getElementStyle(inner, 'height'), 10)
    if (this.props.readMoreButtonVisible) {
      const overflows = inner && wrapper && innerHeight > wrapperHeight
      this.setState({overflows, collapsed: true})
    } else {
      this.setState({overflows: false, collapsed: false})
    }
  }

  appendAboutText(collapsed) {
    collapsed ? this.modifyFocus(this.removeFocus) : this.modifyFocus(this.applyFocus)
  }

  modifyFocus(modify) {
    const aboutText = this.getAboutText()
    const el = document.createElement('div')
    el.innerHTML = `${aboutText}`
    this.contentRef.innerHTML = ''
    this.contentRef.appendChild(modify(el))
  }

  getAboutText() {
    const {isEditor, aboutText, t} = this.props
    return isEditor && !aboutText ? t('demoEvent_aboutSectionText') : aboutText
  }

  removeFocus = el => {
    this.focusableList.forEach(htmlTag => {
      el.querySelectorAll(htmlTag).forEach(focusable => (focusable.tabIndex = -1))
    })
    return el
  }

  applyFocus = el => {
    this.focusableList.forEach(htmlTag => {
      el.querySelectorAll(htmlTag).forEach(focusable => (focusable.tabIndex = 0))
    })
    return el
  }
}
