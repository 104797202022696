import React from 'react'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import s from './full-date.scss'
import {FullDateProps} from './index'

export const FullDate: React.FC<FullDateProps> = ({event}) => {
  const {fullDate} = useEventDateInformation(event.id)

  return (
    <p data-hook="event-full-date" className={s.dateContainer}>
      {fullDate}
    </p>
  )
}
