import {Category, Place, Sector, PlanReservations} from '@wix/ambassador-seating-v1-seating-plan/types'
import {createAction} from '@reduxjs/toolkit'
import {getEventId, WIX_EVENTS_TICKET_DEFINITION_FQDN, PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {getFee, getTax} from '../selectors/tax-and-fee'
import {SeatingState} from '../types'
import {isChooseSeatMode} from '../selectors/navigation'
import {createAsyncAction} from '../services/redux-toolkit'
import {getTicketPriceText} from '../selectors/order-success'

export const describeSeatingPlan = createAsyncAction<Partial<SeatingState>, void>(
  'DESCRIBE_SEATING_PLAN',
  async (_, {getState, extra: {serverApi, flowAPI}}) => {
    const t: TFunction = flowAPI.translations.t as TFunction
    const {tickets, event} = getState()
    const response = await serverApi.describeSeatingPlan(getEventId(event))
    const {
      plan,
      plan: {sectors},
      planReservations,
    } = response

    const placesWithInfo: PlaceWithTicketInfo[] = [
      ...response.plan.categories
        .filter(category => category.totalCapacity)
        .reduce(
          (places, category) => [
            ...places,
            ...category.places
              .filter(place => place.capacity)
              .map(place => getPlaceWithTicketInfo({place, category, sectors, tickets, event, t, planReservations})),
          ],
          [] as PlaceWithTicketInfo[],
        ),
      ...response.plan.uncategorizedPlaces
        .filter(place => place.capacity)
        .map(place => getPlaceWithTicketInfo({place, category: null, sectors, tickets, event, t, planReservations})),
    ]

    return {
      plan,
      places: placesWithInfo,
    }
  },
)

export const selectPrice = createAction<string>('SELECT_PRICE')

interface AddPlaceToBasketArgs {
  placeId: string
  count: number
  origin: string
}

export const addPlaceToBasket = createAsyncAction<{places: PlaceWithTicketInfo[]}, AddPlaceToBasketArgs>(
  'ADD_PLACE_TO_BASKET',
  async ({placeId, count}, {getState}) => {
    const state = getState()
    const chooseSeatMode = isChooseSeatMode(state)

    return {
      places: state.seating.places.map(place => {
        if (place.id === placeId) {
          return {
            ...place,
            quantity: chooseSeatMode ? 1 : count,
            inBasket: Boolean(count),
            selected: false,
            timeAddedToBasket: new Date().getTime(),
          }
        } else {
          return chooseSeatMode ? {...place, quantity: 0, inBasket: false, selected: false} : place
        }
      }),
    }
  },
)

interface SelectPlaceArgs {
  placeId: string
  mobile: boolean
}

export const selectPlace = createAsyncAction<{places: PlaceWithTicketInfo[]}, SelectPlaceArgs>(
  'SELECT_PLACE',
  async ({placeId, mobile}, {getState}) => {
    const state = getState()

    return {
      places: state.seating.places.map(place => {
        if (place.id === placeId) {
          return {
            ...place,
            selected: true,
          }
        } else {
          return mobile ? {...place, selected: false} : place
        }
      }),
    }
  },
)

export const unselectPlace = createAsyncAction<{places: PlaceWithTicketInfo[]}, {placeId: string}>(
  'UNSELECT_PLACE',
  async ({placeId}, {getState}) => {
    const state = getState()

    return {
      places: state.seating.places.map(place =>
        place.id === placeId
          ? {
              ...place,
              selected: false,
            }
          : place,
      ),
    }
  },
)

export const selectLocation = createAction<string>('SELECT_LOCATION')

export const resetFilters = createAsyncAction('RESET_FILTERS', async (_, {dispatch}) => {
  await dispatch(selectPrice(null))
  await dispatch(selectLocation(null))
})

export const setShowAccessibilityMode = createAction<boolean>('SET_SHOW_ACCESSIBILITY_MODE')

const getPlaceWithTicketInfo = ({
  place: {id, label, capacity, elementType},
  category,
  sectors,
  tickets,
  event,
  t,
  planReservations,
}: GetPlaceWithTicketInfoParams): PlaceWithTicketInfo => {
  const {
    externalId,
    config: {color},
  } = category ?? {config: {}}
  const ticketDefinition = tickets.find(
    ticketDef => ticketDef.id === externalId?.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''),
  )
  const [sectorId, elementId] = id.split('-')
  const sector = sectors.find(item => item.id === Number(sectorId))
  const element = sector.elements.find(item => item.id === Number(elementId))

  return {
    id,
    elementType,
    label,
    color,
    capacity: capacity - planReservations.reservations[id].occupied,
    quantity: 0,
    selected: false,
    inBasket: false,
    sector: sectorId === '0' ? undefined : sector.title,
    elementLabel: element.title,
    elementId: element.id,
    ticketId: ticketDefinition?.id,
    ticketName: ticketDefinition?.name,
    ticketDescription: ticketDefinition?.description,
    ticketPrice: ticketDefinition ? getTicketPriceText(ticketDefinition.price, t) : null,
    fees: ticketDefinition
      ? [getTax(event, ticketDefinition, t), getFee(event, ticketDefinition, t)].filter(Boolean).join(', ')
      : null,
  }
}

export enum ERROR {
  TICKET_LIMIT_REACHED = 'TICKET_LIMIT_REACHED',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  SEATS_UNAVAILABLE = 'SEATS_UNAVAILABLE',
  NO_ERROR = '',
}

export const setError = createAction<ERROR>('SET_ERROR')

interface GetPlaceWithTicketInfoParams {
  place: Place
  category: Category
  sectors: Sector[]
  tickets: wix.events.ticketing.TicketDefinition[]
  event: wix.events.Event
  t: TFunction
  planReservations: PlanReservations
}

export const seatingMapButtonClick = createAction<string>('SEATING_MAP_BUTTON_CLICK')
