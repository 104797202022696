import React from 'react'
import classNames from 'classnames'
import {InvoiceBreakdown} from '../../../../invoice-breakdown'
import {getInvoiceItemsWithTicketInfo} from '../../../../../selectors/order-success'
import c from '../../../../classnames.scss'
import {getTicketsWithoutTax} from '../../../../../../../commons/selectors/tickets'
import s from './invoice.scss'
import {TicketInfo} from './ticket-info'
import {PlaceInfo} from './place-info'
import {InvoiceProps} from '.'

export const Invoice = ({hasSeatingPlan, order}: InvoiceProps) => {
  const {
    invoice: {items},
    tickets,
  } = order
  return (
    <div className={classNames(c.evTextFont, c.formLabelsColor)}>
      {hasSeatingPlan
        ? getTicketsWithoutTax(order).map((ticket, index) => <PlaceInfo key={index} ticket={ticket} />)
        : getInvoiceItemsWithTicketInfo(items, tickets).map((item, index) => <TicketInfo key={index} item={item} />)}
      <InvoiceBreakdown containerClass={s.container} />
    </div>
  )
}
