import classNames from 'classnames'
import React from 'react'
import {Tooltip} from 'wix-ui-tpa/Tooltip'
import {
  saleEndedAndHasTickets,
  saleScheduledAndHasTickets,
  saleStartedAndHasTickets,
  shouldShowSaleEndsLabel,
} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Badge} from '../../../badge'
import c from '../../../classnames.scss'
import {QuantityPicker} from '../quantity-picker'
import s from './mobile-ticket.scss'
import {Price} from './price'
import {TicketDescription} from './ticket-description'
import {SaleEndsLabel} from './sale-ends-label'
import {GoesOnSaleLabel} from './goes-on-sale-label'
import {TicketProps, PickerProps} from '.'

export const Ticket = ({
  ticket,
  selectedQuantity,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  maxTicketsPerReservation,
  withSeatingPlan,
}: TicketProps) => {
  const {t} = useTranslation()
  const {id, limitPerCheckout, name, description} = ticket

  return (
    <div key={id} className={classNames(s.ticket, c.evTextColor, c.evTextFont)} data-hook="ticket">
      {!limitPerCheckout ? <SoldOut /> : null}
      {saleEndedAndHasTickets(ticket) && <SaleEnded />}
      <div
        className={classNames(s.name, c.evTicketNameColor, c.evTicketNameFont)}
        data-hook="ticket-name"
        aria-label={`${t('ticketsThankYou.ticketName')}: ${name}`}
      >
        {name}
      </div>
      <Price className={s.price} ticket={ticket} />
      {shouldShowSaleEndsLabel(ticket) && (
        <SaleEndsLabel endDate={ticket.salePeriod.formattedSaleEndDate} className={s.saleEndsContainer} />
      )}
      {saleStartedAndHasTickets(ticket) ? (
        <Picker
          id={id}
          maxTicketsPerReservation={maxTicketsPerReservation}
          selectedQuantity={selectedQuantity}
          limitPerCheckout={limitPerCheckout}
          availableTicketsQuantity={availableTicketsQuantity}
          registrationClosed={registrationClosed}
          withSeatingPlan={withSeatingPlan}
          onChange={onChange}
        />
      ) : null}
      {saleScheduledAndHasTickets(ticket) && (
        <GoesOnSaleLabel startDate={ticket.salePeriod.formattedSaleStartDate} className={s.goesOnSaleContainer} />
      )}
      {description ? (
        <div className={s.description}>
          <TicketDescription ticketDefId={id} description={description} />
        </div>
      ) : null}
      <div className={classNames(c.evTicketDividerColor, s.mobileCheckoutDivider)} />
    </div>
  )
}

const Picker = ({
  id,
  maxTicketsPerReservation,
  selectedQuantity,
  limitPerCheckout,
  availableTicketsQuantity,
  registrationClosed,
  withSeatingPlan,
  onChange,
}: PickerProps) => {
  const {t} = useTranslation()
  const showTooltip = selectedQuantity > 0 && availableTicketsQuantity === 0

  if (withSeatingPlan && !registrationClosed) {
    return <div>{t('ticketsPicker_onSale')}</div>
  }

  return (
    <Tooltip
      className={s.tooltip}
      disabled={!showTooltip}
      shown={showTooltip}
      moveBy={{x: 10, y: 0}}
      minWidth={127}
      placement="right"
      content={
        maxTicketsPerReservation === 1
          ? t('ticketLimitPerReservationReached_singular')
          : t('ticketLimitPerReservationReached', {count: maxTicketsPerReservation})
      }
    >
      <div className={s.quantity}>
        <QuantityPicker
          quantity={selectedQuantity}
          min={0}
          max={Math.min(availableTicketsQuantity + selectedQuantity, limitPerCheckout)}
          disabled={!limitPerCheckout || registrationClosed}
          onChange={value => onChange({ticketId: id, count: value})}
        />
      </div>
    </Tooltip>
  )
}

const SoldOut = () => {
  const {t} = useTranslation()

  return (
    <div className={s.soldOut}>
      <Badge>{t('mobile.ticketsPicker.soldOut')}</Badge>
    </div>
  )
}

const SaleEnded = () => {
  const {t} = useTranslation()

  return (
    <div className={s.saleEnded}>
      <Badge>{t('ticketsPicker.saleEndedBadge')}</Badge>
    </div>
  )
}
