import React from 'react'
import {
  saleEndedAndHasTickets,
  saleScheduledAndHasTickets,
  saleStartedAndHasTickets,
} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {GoesOnSaleLabel} from '../goes-on-sale-label'
import {SaleEndedBadge} from '../sale-ended-badge'
import {SoldOutLabel} from '../sold-out-label'
import s from './total-column.scss'
import {TotalColumnProps} from '.'

export const TotalColumn = ({total, ticket, withSeatingPlan}: TotalColumnProps) => {
  const {t} = useTranslation()
  const {limitPerCheckout, salePeriod} = ticket
  const ariaLabel = withSeatingPlan ? t('ticketsPicker_onSale') : `${t('ticketsPicker.subtotal')}: ${total}`

  return (
    <>
      {saleStartedAndHasTickets(ticket) && (
        <p data-hook="total" role="cell" aria-label={ariaLabel}>
          {withSeatingPlan ? t('ticketsPicker_onSale') : total}
        </p>
      )}
      {saleScheduledAndHasTickets(ticket) && (
        <GoesOnSaleLabel startDate={salePeriod.formattedSaleStartDate} className={s.goesOnSaleContainer} />
      )}
      <SoldOutLabel limit={limitPerCheckout} />
      {saleEndedAndHasTickets(ticket) && <SaleEndedBadge />}
    </>
  )
}
