import {getFormattedPrice, saleScheduled, saleStartedAndHasTickets} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {Tooltip} from 'wix-ui-tpa/Tooltip'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {calculateSubPrice} from '../../../../services/payment'
import c from '../../../classnames.scss'
import {ColumnConfig, TableRow} from '../../../table-row'
import {FixedHeightColumn} from './fixed-height-column'
import {Price} from './price'
import {Quantity} from './quantity'
import {TicketNameColumn} from './ticket-name-column'
import s from './ticket.scss'
import {TotalColumn} from './total-column'
import {TicketProps} from '.'

export const Ticket = ({
  ticket,
  selectedQuantity,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  maxTicketsPerReservation,
  shouldTicketLimitTooltipShow,
  withSeatingPlan,
}: TicketProps) => {
  const {t} = useTranslation()
  const {id, limitPerCheckout, price} = ticket
  const total = getFormattedPrice(calculateSubPrice(Number(price.amount), selectedQuantity), price.currency)

  const nameColumn: ColumnConfig = {
    className: s.nameColumn,
    content: (
      <div className={s.nameContainer}>
        <TicketNameColumn ticket={ticket} />
      </div>
    ),
  }

  const priceColumn: ColumnConfig = {
    content: (
      <FixedHeightColumn
        className={classNames({[s.total]: withSeatingPlan && registrationClosed && !saleScheduled(ticket)})}
        ticketDefId={id}
      >
        <Price ticket={ticket} />
      </FixedHeightColumn>
    ),
  }

  const totalColumn: ColumnConfig = {
    content: (
      <FixedHeightColumn className={s.total} ticketDefId={id}>
        <TotalColumn
          total={total}
          ticket={ticket}
          withSeatingPlan={withSeatingPlan}
          registrationClosed={registrationClosed}
        />
      </FixedHeightColumn>
    ),
  }

  const getWithSeatingsColumns = () => {
    if (registrationClosed && !saleScheduled(ticket)) {
      return [nameColumn, {content: ''}, {content: ''}, priceColumn]
    }
    return [nameColumn, priceColumn, {content: ''}, totalColumn]
  }

  const columns: ColumnConfig[] = withSeatingPlan
    ? getWithSeatingsColumns()
    : [
        nameColumn,
        priceColumn,
        {
          content: saleStartedAndHasTickets(ticket) && (
            <FixedHeightColumn ticketDefId={id}>
              <Tooltip
                className={s.tooltip}
                disabled={!shouldTicketLimitTooltipShow}
                shown={shouldTicketLimitTooltipShow}
                minWidth={140}
                content={
                  maxTicketsPerReservation === 1
                    ? t('ticketLimitPerReservationReached_singular')
                    : t('ticketLimitPerReservationReached', {count: maxTicketsPerReservation})
                }
              >
                <Quantity
                  selectedQuantity={selectedQuantity}
                  availableTicketsQuantity={availableTicketsQuantity}
                  limit={limitPerCheckout}
                  disabled={!limitPerCheckout || registrationClosed}
                  onChange={onChange}
                  id={id}
                />
              </Tooltip>
            </FixedHeightColumn>
          ),
          className: s.overflow,
        },
        totalColumn,
      ]

  return (
    <TableRow
      config={{
        itemKey: id,
        responsiveHideColumns: [1],
        addDivider: true,
        className: classNames(s.row, c.evTextFont, c.evTextColor),
        dividerClassName: classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor),
        dataHook: DH.TICKET,
        columns,
      }}
    />
  )
}
