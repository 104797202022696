import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import c from '../../../classnames.scss'
import {ColumnConfig, TableRow, TableRowType} from '../../../table-row'
import s from './header.scss'
import {HeaderProps} from '.'

export const Header = ({title, withSeatingPlan, registrationClosed, hasScheduledTickets}: HeaderProps) => {
  const {t} = useTranslation()
  const primaryColumnClassNames = classNames(s.column, c.evSubtitlesFont, c.evSubtitlesColor)
  const secondaryColumnClassNames = classNames(s.column, c.evTextFont, c.evTextColor)
  const primaryContentClassNames = classNames(c.evSubtitlesFont, c.evSubtitlesColor)
  const secondaryContentClassNames = classNames(c.evTextFont, c.evTextColor)

  const titleColumn: ColumnConfig = {
    content: title,
    className: primaryColumnClassNames,
    dataHook: DH.TICKETS_PICKER_HEADER_TITLE,
    tagName: 'h2',
    contentClassName: primaryContentClassNames,
  }

  const priceColumn: ColumnConfig = {
    content: t('ticketsThankYou.price'),
    className: secondaryColumnClassNames,
    dataHook: DH.TICKETS_PICKER_HEADER_PRICE,
    tagName: 'h3',
    contentClassName: secondaryContentClassNames,
  }

  const getWithSeatingsColumns = () => {
    if (registrationClosed && !hasScheduledTickets) {
      return [titleColumn, {content: ''}, {content: ''}, priceColumn]
    }
    return [
      titleColumn,
      priceColumn,
      {content: ''},
      {
        content: t('ticketsPicker_status'),
        className: secondaryColumnClassNames,
        tagName: 'h3',
        contentClassName: secondaryContentClassNames,
      },
    ]
  }

  const columns: ColumnConfig[] = withSeatingPlan
    ? getWithSeatingsColumns()
    : [
        titleColumn,
        priceColumn,
        {
          content: t('ticketsThankYou.quantity'),
          className: secondaryColumnClassNames,
          dataHook: DH.TICKETS_PICKER_HEADER_QUANTITY,
          ariaLabel: t('a11y.quantity'),
          tagName: 'h3',
          contentClassName: secondaryContentClassNames,
        },
        {
          content: t('ticketsThankYou.subTotal'),
          className: secondaryColumnClassNames,
          dataHook: DH.TICKETS_PICKER_HEADER_TOTAL,
          tagName: 'h3',
          contentClassName: secondaryContentClassNames,
        },
      ]

  return (
    <div role="rowgroup">
      <TableRow
        config={{
          columns,
          itemKey: DH.TICKETS_PICKER_TITLES,
          dataHook: DH.TICKETS_PICKER_TITLES,
          addDivider: true,
          type: TableRowType.header,
          responsiveHideColumns: [1],
          dividerClassName: classNames(s.divider, c.evTicketDividerColor, c.evTicketDividerSize),
        }}
      />
    </div>
  )
}
