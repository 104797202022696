import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {TicketInfo as Presentation} from './ticket-info'
import {TicketInfoOwnProps, TicketInfoRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): TicketInfoRuntimeProps => {
  return {
    showAccessibilityMode: state.seating.showAccessibilityMode,
  }
}

export const TicketInfo = connect<TicketInfoOwnProps, TicketInfoRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
