import React from 'react'
import {EventDetailsButtonStyle} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {isHollowButton, isRoundedButton} from '../../../selectors/settings'
import cl from '../../classnames.scss'
import s from './rsvp-info-button.scss'
import {RsvpInfoButtonProps} from '.'

const getContainerClasses = (buttonStyle: EventDetailsButtonStyle, mobile: boolean) => {
  const fontClass = isHollowButton(buttonStyle) ? cl.rsvpHollowButtonFont : cl.rsvpButtonFont
  const colorClass = isHollowButton(buttonStyle) ? cl.rsvpHollowButtonColor : cl.rsvpButtonColor
  const backgroundClass = isHollowButton(buttonStyle) ? '' : cl.rsvpButtonBackgroundColor
  const borderClass = isHollowButton(buttonStyle) ? cl.rsvpButtonBorder : ''
  const radiusClass = isRoundedButton(buttonStyle) ? cl.rsvpButtonRoundedBorderRadius : cl.rsvpButtonBorderRadius

  const classes = [fontClass, colorClass, backgroundClass, borderClass, radiusClass, s.container]

  if (mobile) {
    classes.push(s.mobileContainer)
  }

  return classes.join(' ')
}

export const RsvpInfoButton: React.FC<RsvpInfoButtonProps> = ({
  messages,
  dataHook,
  buttonStyle,
  mobile,
  href,
  onClick,
}) => {
  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onClick()
  }
  return (
    <div className={getContainerClasses(buttonStyle, mobile)} data-hook={dataHook}>
      <div>{messages.message}</div>
      <a
        className={s.link}
        data-hook={DH.RSVP_INFO_BUTTON}
        href={href}
        onClick={onClick && !href ? handleOnClick : null}
      >
        {messages.exploreEventsActionLabel}
      </a>
    </div>
  )
}
