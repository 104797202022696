import {
  isRegistrationClosedVisible,
  getExternalLink,
  hasImage,
  isTicketed,
  isExternal,
  isWithoutRegistration,
  isRegistrationWithCustomDates,
  isRegistrationScheduled,
} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMemberRsvpExists} from '../../../selectors/member-rsvp'
import {getButtonStyle, getTexts, isRsvpButtonVisibilityEnabled} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {isMobile} from '../../../../../commons/selectors/environment'
import {RegistrationButtonOwnProps, RegistrationButtonRuntimeProps} from './interfaces'
import {RegistrationButton as Presentation} from './registration-button'

const mapRuntime = ({state}: DetailsPageAppProps): RegistrationButtonRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const ticketedEvent = isTicketed(event)

  return {
    registrationClosed: isRegistrationClosedVisible(event),
    withoutRegistration: isWithoutRegistration(event),
    memberRsvpExists: isMemberRsvpExists(state),
    ticketedEvent,
    rsvpButtonText: getTexts(componentSettings).rsvpButtonText,
    buttonStyle: getButtonStyle(componentSettings),
    imageVisible: hasImage(event),
    isMobile: isMobile(state),
    ticketsUnavailable: ticketedEvent && !state.tickets.length,
    url: getExternalLink(event),
    isExternalLink: isExternal(event),
    rsvpButtonEnabled: isRsvpButtonVisibilityEnabled(state.component.settings),
    registrationScheduled: isRegistrationScheduled(event),
    registrationWithCustomDates: isRegistrationWithCustomDates(event),
    eventId: event.id,
    homePageUrl: state.environment.homePageUrl,
  }
}

export const RegistrationButton = withTranslation()(
  connect<RegistrationButtonOwnProps, RegistrationButtonRuntimeProps>(mapRuntime)(Presentation),
)

export * from './interfaces'
