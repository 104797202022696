import classNames from 'classnames'
import React from 'react'
import c from '../../../../classnames.scss'
import {TicketDescriptionProps} from './interfaces'
import s from './ticket-description.scss'

export const TicketDescription: React.FC<TicketDescriptionProps> = ({
  ticketDefId,
  description,
  expanded,
  expandDescription,
  collapseDescription,
  isMobile,
  t,
  isHollowButton,
}) => {
  const text = `ticketsPicker.ticketDescription.show${expanded ? 'Less' : 'More'}`
  const buttonClasses = `${isHollowButton ? c.readMoreHollowTextColor : c.readMoreTextColor} ${
    !isMobile ? s.link : s.mobileLink
  }`

  return (
    <div className={classNames(c.evTextFont, c.evTextColor, s.description, {[s.expanded]: expanded})}>
      <div aria-live="polite" className={s.description}>
        {expanded && <p data-hook="description">{description}</p>}
      </div>
      <button
        data-hook="more-button"
        aria-expanded={expanded}
        className={buttonClasses}
        onClick={() => {
          expanded ? collapseDescription(ticketDefId) : expandDescription(ticketDefId)
        }}
      >
        {t(text)}
      </button>
    </div>
  )
}
