import {saleScheduledAndHasTickets, shouldShowSaleEndsLabel} from '@wix/wix-events-commons-statics'
import React from 'react'
import {Badge} from 'wix-ui-tpa/Badge'
import s from './custom-dates-label.scss'
import {CustomDatesLabelProps} from '.'

export const CustomDatesLabel = ({ticket, t}: CustomDatesLabelProps) => {
  if (!ticket) {
    return null
  }

  if (saleScheduledAndHasTickets(ticket)) {
    return (
      <Badge className={s.label}>
        {t('seatings_custom_dates_goes_on_sale', {date: ticket.salePeriod.formattedSaleStartDate})}
      </Badge>
    )
  }

  if (shouldShowSaleEndsLabel(ticket)) {
    return (
      <Badge className={s.label}>
        {t('seatings_custom_dates_sale_ends', {date: ticket.salePeriod.formattedSaleEndDate})}
      </Badge>
    )
  }

  return null
}
