import {TFunction} from '@wix/wix-i18n-config'
import {State} from '../types'
import {RegistrationErrorKey} from '../../../commons/enums'

const FORM_SUBMIT_ERROR_TIMEOUT = 15 // seconds

export const canRetryFormSubmit = (state: State) => state.formSubmitErrors.canRetry

export const getLastFormErrorTime = (state: State) => {
  return Number(state.formSubmitErrors.errorTime)
}

export const secondsTillFormSubmitIsAllowed = (errorTime: number) => {
  if (errorTime) {
    const secondsElapsed = Math.floor((Number(new Date()) - errorTime) / 1000)
    return secondsElapsed >= FORM_SUBMIT_ERROR_TIMEOUT ? 0 : FORM_SUBMIT_ERROR_TIMEOUT - secondsElapsed
  }
}
export const getFormSubmitError = (state: State, t: TFunction) => {
  const handlers = {
    [RegistrationErrorKey.RSVP_CLOSED]: () => t('formError.rsvpClosed'),
    [RegistrationErrorKey.GUEST_LIMIT_REACHED]: () =>
      t('formError.guestLimitExceeded', {remainingSlots: state.formSubmitErrors.error.max_rsvp_size}),
    [RegistrationErrorKey.WAITING_LIST_UNAVAILABLE]: () => t('formError.waitingListUnavailable'),
    [RegistrationErrorKey.GUEST_ALREADY_REGISTERED]: () => t('formError.guestAlreadyRegistered'),
    [RegistrationErrorKey.MEMBER_ALREADY_REGISTERED]: () => t('formError.guestAlreadyRegistered'),
    [RegistrationErrorKey.UNEXPECTED_RSVP_RESPONSE]: () => t('formError.unexpectedRsvpResponse'),
  }

  if (state.formSubmitErrors.error?.error_key) {
    const errorHandlerKey = Object.keys(handlers).find(key => key === state.formSubmitErrors.error?.error_key)
    if (errorHandlerKey && handlers[errorHandlerKey]) {
      return handlers[errorHandlerKey]()
    } else if (canRetryFormSubmit(state)) {
      return t('formError.retriableError')
    } else {
      return t('formError.generalError')
    }
  }
}
